import Vue from 'vue'

export default {
  state: {
    orders: null,
    order: null,
    act: null,
    status: null,
    accounting: null,
    registry: null
  },

  mutations: {
    SET_ORDERS(state, payload) {
      state.orders = payload
    },

    SET_ORDERS_USERSCONCAT(state, payload) {
      let concat = state.orders.concat(payload)
      state.orders = concat
    },

    SET_ORDER(state, payload) {
      state.order = payload
    },

    SET_ORDER_ACT(state, payload) {
      state.act = payload
    },

    SET_ACCOUNTING_ACT(state, payload) {
      state.accounting = payload
      setTimeout(() => {
        state.accounting = null
      }, 200);
    },

    SET_REGISTRY_ACT(state, payload) {
      state.registry = payload
      setTimeout(() => {
        state.registry = null
      }, 200);
    },

    SET_STATUS(state, payload) {
      state.status = payload
    }
  },

  actions: {
    getOrders({ commit}, {offset, limit, startDate, completeDate}) {
      Vue.http_admin.get(
        "api/orders" + "?offset=" + offset + "&limit=" + limit + (startDate ? '&startDate=' + startDate : '') + (completeDate ? '&completeDate='+completeDate : '')
      )

      .then(response => {

        if (offset === 0) {
          commit('SET_ORDERS', response.data)
        } else {
          commit('SET_ORDERS_USERSCONCAT', response.data)
        }
      })

      .catch(error => {
        console.log( error );
      });
    },

    getOrder({commit} , id ) {
      Vue.http_admin.get(
        `api/order/${id}`
      )

      .then(response => {
        commit('SET_ORDER', response.data)
      })

      .catch(error => {
        console.log( error );
      });
    },

    getOrderAct({ commit}, id) {
      Vue.http_admin.get(
        `api/order/${id}/act`, {
          responseType: 'blob'
        }
      )

      .then(response => {
        commit('SET_ORDER_ACT', response.data)
      })

      .catch(error => {
        console.log( error );
      });
    },

    getAccountingAct({commit}, {from, to}) {
      Vue.http_admin.get(
          `api/orders/accounting?startDate=${from}&endDate=${to}`, {
            responseType: 'blob'
          }
      ).then(response => {
        commit('SET_ACCOUNTING_ACT', response.data)
      }).catch(error => {
        console.log(error);
      });
    },

    getRegistry({ commit }, {from, to}) {
      Vue.http_admin.get(
        `api/orders/registry?startDate=${from}&endDate=${to}`, {
          responseType: 'blob'
        }
      )

      .then(response => {
        commit('SET_REGISTRY_ACT', response.data)
      })

      .catch(error => {
        console.log(error);
      });
    },

    changeStatus({ commit }, { orderId, status, price, onSuccess, onError }) {
      const bodyParameters = { "status": status, "price": price }
      Vue.http_admin.put(
        `api/order/${orderId}`,
        bodyParameters
      )

      .then(() => {
        onSuccess && onSuccess()
        commit('SET_STATUS', bodyParameters)
      })

      .catch(error => {
        onError && onError()
        console.log( error );
      });
    },
  },

  getters: {
    getOrders: (state) => {
      return state.orders
    }
  }
}
