<template>
  <div class="messages">
    <div class="messages-main">
      <div class="messages-container">
        <div class="messages-list">
          <div>
            <h1 class="messages-heading" v-if="notifications.length">
              Сообщения
            </h1>
            <div class="messages-list-wrap" @scroll="handleScroll">
              <transition-group name="list" tag="div" class="messages-list">
                <message-card
                  v-for="(message, idx) in notifications"
                  :key="idx"
                  :item="message"
                  @open="openModal"
                />
              </transition-group>
              <div v-if="loading" class="loading-indicator">Loading...</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <notification-modal
      v-if="modalVisible"
      :item="selectedMessage"
      :visible="modalVisible"
      @close="closeModal"
    />
  </div>
</template>

<script>
import MessageCard from "@/components/Notifications/MessageCard";
import NotificationModal from "@/components/Notifications/NotificationModal";
import { mapState } from "vuex";
import { io } from "socket.io-client";
import { jwtDecode } from "jwt-decode";

export default {
  components: {
    MessageCard,
    NotificationModal,
  },
  data() {
    return {
      notifications: [],
      modalVisible: false,
      selectedMessage: null,
      loading: false,
      finished: false,
    };
  },
  computed: {
    ...mapState({
      messages: (state) => state.messages.messages,
      offset: (state) => state.messages.offset,
      limit: (state) => state.messages.limit,
    }),
  },
  watch: {
    messages(newMessages) {
      if (newMessages.length < this.limit) {
        this.finished = true;
      }
      this.notifications.push(...newMessages);
    },
  },
  created() {
    this.loadMessages({ reset: true });
    // содержит информацию о юзере
    const decodedToken = jwtDecode(localStorage.getItem("jwt"));

    this.socket = io(process.env.VUE_APP_baseURL, {
      query: {
        userId: decodedToken.id,
        unreadNotifIdOffset: Number(localStorage.getItem("lastUnreadNotifId")),
      },
    });

    this.socket.on("notification", (data) => {
      this.notifications.push(this.messages, data);
    });
  },
  beforeDestroy() {
    if (this.socket) {
      this.socket.disconnect();
    }
  },
  methods: {
    openModal(message) {
      this.selectedMessage = message;
      this.modalVisible = true;
    },
    closeModal() {
      this.modalVisible = false;
      this.selectedMessage = null;
    },
    handleScroll(event) {
      const bottomOfList = event.target.scrollHeight - event.target.scrollTop === event.target.clientHeight;
      if (bottomOfList && !this.loading && !this.finished) {
        this.loadMessages();
      }
    },
    loadMessages({ reset = false } = {}) {
      this.loading = true;
      this.$store.dispatch('fetchMessages', { reset })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.messages-main {
  background: $black-messages;
  position: relative;
  z-index: 1;
  padding-bottom: 100px;
  min-height: calc(100vh - 184px);

  @include display-less(tablet) {
    padding-bottom: 120px;
  }
}

.messages-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  
  &-wrap {
    height: 500px;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.messages-container {
  position: relative;
  width: 100%;
  max-width: 640px;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;

  @include display-less(phablet) {
    padding-left: 20px;
    padding-right: 20px;
  }

  @include display-less(smart) {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.messages-heading {
  font: 700 32px/43px $roboto;
  color: $white-lime;
  padding-top: 38px;
  margin-bottom: 40px;
  text-align: start;
}

.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}

.list-enter,
.list-leave-to {
  opacity: 0;
  transform: translateY(30px);
}
</style>
