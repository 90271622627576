<template>
  <div class="basket">
    <div
        :class="{ basketEmptyActive: !getAmountOfProducts }"
        class="basket-main"
    >
      <span @click="back()" class="back"></span>
      <div v-if="getAmountOfProducts" class="container">
        <h1 class="basket-heading">Корзина</h1>
        <template v-if="basketData">
          <basket-product
              v-for="order in basketData.purchases.goods"
              :key="order.id"
              :order="order"
              :productId="order.id"
              :userId="userId"
              :custom="false"
          />
          <basket-product
              v-for="order in basketData.purchases.customGoods"
              :key="order.id"
              :order="order"
              :productId="order.id"
              :userId="userId"
              :custom="true"
          />
        </template>
        <div class="basket-bottom">
          <div class="basket-total">
            <div class="basket-total__txt">Итого:</div>
            <div v-if="basketData" class="product-basket-price">
              {{ parseInt(basket.purchases.totalPrice) }}
              <span class="product-basket-price__ico"></span>
            </div>
          </div>
        </div>
        <div class="basket-bottom_parent">
          <div class="basket-bottom-comment">
            <div class="basket-comment-wrap">
              <label
                  :class="{ active: orderComment.length }"
                  class="profile-form__label"
              >Комментарий к заказу</label
              >
              <textarea
                  class="basket-comment"
                  :class="{ active: orderComment.length }"
                  v-model="orderComment"
              >
              </textarea>
              <span
                  class="basket-comment__clear"
                  @click="clearComment()"
              ></span>
            </div>
            <div :class="[change_css ? 'position_up' : 'basket-bottom-btn-wrap']">
              <warn-text
                  :notification="juridical_notification"
                  @accepted="acceptedJuridicalNotification"
                  @showText="onPosition"
                  :citizenship="citizenship"
              />
              <button
                  :disabled='exceed || (!juridicalNotificationAccepted && citizenship === "ru")'
                  class="basket-bottom-btn"
                  @click="makeOrder"
              >
                Оформить заказ
              </button>
            </div>
          </div>
        </div>

        <div style="color: red; margin-top: 20px">
          {{ orderError }}
        </div>
      </div>
      <div v-if="!getAmountOfProducts" class="container basket-empty-container">
        <h1 class="basket-heading">Корзина</h1>
        <div class="basket-empty-heading" v-if="!success">Корзина пуста!</div>
        <div class="basket-empty-heading success" v-if="success">
          Ваш заказ оформлен!
        </div>
        <div class="basket-back" @click="back()">
          <span class="basket-back__icon"></span>
          <span class="basket-back__txt">Назад</span>
        </div>
      </div>

      <div
          class="basket-popup"
          v-if="popupIsActive && juridicalNotificationAccepted"
      >
        <div>Итоговая стоимость заказа превышает Ваш баланс!</div>
      </div>
      <div class="basket-popup" v-if="noAgreement">
        <div>Примите условие оформления заказа!</div>
      </div>
    </div>
  </div>
</template>

<script>
import BasketProduct from "@/components/BasketProduct";
import WarnText from "@/components/WarnText";
import {mapState, mapGetters} from "vuex";
import { jwtDecode } from "jwt-decode";

const juridical_notification = `
        Уважаемый участник Мегабонус! Принимая участие в программе лояльности Вы :
        <ol>
            <li>Подтверждаете свое согласие на получение от ООО " ФИТ Автосервис" поощрения в натуральной форме ( в форме товаров) и в денежной форме (в размере 14.94 % от стоимости поощрения в натуральной форме ).</li>
            <li>Подтверждаете свое согласие на получение от ООО " ФИТ Автосервис" поощрения в натуральной форме в размере более 20 % от всей суммы поощрения.</li>
            <li>Подтверждаете свое согласие на удержание из суммы поощрения НДФЛ в размере 13 %.</li>
            <li>Подтверждаете факт ознакомления с тем, что ООО " ФИТ Автосервис" является налоговым агентом и производит удержание , оплату НДФЛ и подачу деклараций с сумм начисленного поощрения в органы Федеральной Налоговой службы за участника программы лояльности .</li>
            <li>Подтверждаете факт ознакомления со всей информацией, касающейся выплат поощрения за участие в программе лояльности Мегабонус и порядка удержания и уплаты НДФЛ, а также с условиями программы .</li>
            <li>Подтверждаете факт ознакомления с тем, что при получении участником программы лояльности поощрения в натуральной форме (в форме товаров) необходимо осуществить проверку его целостности и работоспособности. В случае, если участник не осуществил проверку целостности и работоспособности поощрения в натуральной форме (в форме товаров) при его получении и в последствии обнаружил признаки его ненадлежащего качества, поощрения в натуральной форме (в форме товаров) замене не подлежат.</li>
            <li>Подтверждаете факт ознакомления с условиями замены полученного участником программы лояльности поощрения в натуральной форме (в форме товаров) при обнаружении его ненадлежащего качества в соответствии с законодательством РФ:
                <ul>
                    <li>•  При возврате полученного участником программы лояльности поощрения в натуральной форме (в форме товаров) ненадлежащего качества участник программы лояльности вправе заменить товар на аналогичный надлежащего качества либо выбрать любой другой товар, указанный на официальном сайте программы Мегабонус, но в пределах суммы бонусов, списанных со счета участника за ранее полученный и возвращенный им товар (стоимость выбранного товара для замены должна быть либо равна, либо быть меньше, стоимости ранее полученного участником и возвращенного товара), при этом ранее списанные бонусы обратно на счет участника не возвращаются и с вновь накопленными бонусами не суммируются.</li>
                    <li>•  В случае если участник для замены выбрал товар меньшей стоимостью бонусов, чем стоимость ранее полученного и возвращенного им товара, то оставшаяся сумма бонусов сгорает (за исключением вновь накопленной суммы бонусов).</li>
                    <li>•  Обязательными условиями замены полученного участником программы лояльности поощрения в натуральной форме (в форме товаров) является возврат товара по месту нахождения ООО «ФИТ Автосервис» и подтверждение его ненадлежащего качества.</li>
                </ul>
            </li>
        </ol>
      `;
export default {

  data() {
    return {
      userId: "",
      basketData: "",
      orderComment: "",
      orderError: "",
      popupIsActive: false,
      success: false,
      juridical_notification: juridical_notification,
      juridicalNotificationAccepted: false,
      noAgreement: false,
      change_css: null,
      citizenship: null,
    };
  },
  components: {
    BasketProduct,
    WarnText,
  },
  computed: {
    ...mapState({
      userData: (state) => state.profile.user,
      basket: (state) => state.basket.basket,
    }),

    ...mapGetters(["getAmountOfProducts"]),

    exceed() {
      if (this.basketData && this.userData) {
        return (
            this.basketData.purchases.totalPrice > this.userData.account.balance
        );
      }
      return false;
    },
  },

  watch: {
    
    userData() {
      if (this.userData) {
        this.userId = this.userData.id;
        this.$store.dispatch("fetchBasket");
        this.citizenship = this.userData.country.code;
      } 
    },



    basket() {
      if (this.basket) {
        this.basketData = this.basket;
        if (this.exceed) {
          this.popupIsActive = false;
          setTimeout(() => {
            this.popupIsActive = true;
          }, 150);
        }
      }
    },
  },


  methods: {
    acceptedJuridicalNotification(data) {
      this.juridicalNotificationAccepted = data.accepted;
    },
    back() {
      this.$router.go(-1);
    },

    clearComment() {
      this.orderComment = "";
    },

    makeOrder() {
      if (!this.juridicalNotificationAccepted && this.citizenship === "ru") {
        this.noAgreement = true;
        setTimeout(() => {
          this.noAgreement = false;
        }, 5000);
        return;
      }
      let params = {
        user: this.userInstance,
        order: {},
        custom: false,
        comment: this.orderComment,
      };
      if (!this.userData.isCompleted) {
        this.$router.push({name: "profile"});
      }
      this.$store.dispatch("upsertBasket", params).then(() => {
        this.$store
            .dispatch("makeOrder")
            .then(() => {
              this.$metrika.reachGoal("checkout");
            })
            .catch((error) => {
              this.orderError = error.response.data.error.message;
              setTimeout(() => {
                this.orderError = "";
              }, 5000);
            });
        this.success = true;
      });
    },
    onPosition(data) {
      this.change_css = data;
    },
  },

  created() {
    this.$store.dispatch("fetchUser");
    const decodedToken = jwtDecode(localStorage.getItem('jwt'));
    this.userId = decodedToken.id;
    this.userInstance = decodedToken;
    
  },
};
</script>

<style lang="scss" scoped>
.basket-main {
  background: #1a1c1c;
  position: relative;
  z-index: 1;
  padding-bottom: 100px;
  min-height: 100vh;
  @include display-less(tablet) {
    padding-bottom: 120px;
  }
}

.basket-heading {
  font: 200 22px/26px $roboto;
  color: #fffefa;
  padding-top: 38px;
  margin-bottom: 40px;
  text-align: center;
}

.product-basket-price {
  font: 400 16px/19px $roboto;
  color: #fffefa;
  display: flex;
  margin: 5px auto 0 0;

  &__ico {
    width: 15px;
    height: 14px;
    display: block;
    background-image: url("../assets/images/icons/coins.svg");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    align-self: flex-end;
    margin: 0 0 2px 5px;
  }
}

.basket-bottom_parent {
  position: relative;
  height: 335px;
}

.basket-bottom {
  display: flex;
  align-items: center;
  margin-top: 80px;
  @include display-less(tablet) {
    flex-direction: column;
    margin-top: 60px;
  }
}

.basket-bottom-comment {
  display: flex;
  margin-top: 80px;
  position: absolute;
  width: 100%;
  @include display-less(tablet) {
    flex-direction: column;
    margin-top: 60px;
    position: static;
  }
}

.basket-bottom-btn-wrap {
  margin: auto 0 4px 1em;
  width: 34%;
  @include display-less(tablet) {
    padding-left: 0;
    position: absolute;
    bottom: 30px;
    left: 20px;
    right: 20px;
    margin: 1em 0;
  }
}

.position_up {
  position: relative;
  transform: translateY(-33%);
  -moz-transform: translateY(-33%);
  margin: 0 0 0 1em;
  backface-visibility: hidden;
  @include display-less(tablet) {
    transform: translateY(0);
    margin: 1em 0;
  }
}

.basket-bottom-btn {
  display: block;
  width: 100%;
  height: 50px;
  background: none;
  border: 1px solid #ff9c43;
  border-radius: 4px;
  font: 700 18px/48px $roboto;
  color: #fffefa;
  text-align: center;
  cursor: pointer;
  padding-left: 15px;
  padding-right: 15px;
  transition: all 0.2s;
  //margin: auto 0 0 1em;

  &:hover {
    background: #ff9c43;
    color: #000;
  }

  &:disabled {
    cursor: not-allowed;
    background-color: gray;
    border: none;
    color: black;

    &:hover {
      //background: none;
      //color: #FFFEFA;
      background-color: gray;
    }
  }
}

.basket-total {
  display: flex;
  align-items: center;
  @include display-less(tablet) {
    margin-right: auto;
  }

  &__txt {
    font: 500 16px/19px $roboto;
    color: #ff9c43;
    margin-right: 10px;
  }
}

.back {
  display: block;
  position: absolute;
  width: 15px;
  height: 15px;
  right: 15px;
  top: 15px;
  background-image: url("../assets/images/icons/close-menu.svg");
  cursor: pointer;
}

.basket-empty-container {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.basketEmptyActive {
  padding-bottom: 40px;
}

.basket-back {
  display: flex;
  align-items: center;
  margin: 150px auto 0 auto;
  cursor: pointer;

  &__icon {
    display: block;
    width: 15px;
    height: 15px;
    margin-right: 10px;
    background-image: url("../assets/images/icons/arrow-left-green.svg");
  }

  &__txt {
    color: #fffefa;
    font: 500 18px/21px $roboto;
  }
}

.basket-empty-heading {
  text-align: center;
  margin-top: 110px;
  color: #838383;
  font: 400 18px/21px $roboto;

  &.success {
    color: #ff9c43;
  }
}

.basket-comment-wrap {
  flex-basis: 70%;

  label {
    display: block;
    height: 16px;
    font: 400 14px/16px $roboto;
    color: #f9f9f9;
    margin-bottom: 10px;

    &.active {
      color: #ff9c43;
    }

    @include display-less(tablet) {
      font-size: 13px;
    }
  }
}

.basket-comment {
  width: 100%;
  height: 150px;
  background: #1a1c1c;
  border: 1px solid #5a5a5a;
  border-radius: 4px;
  padding: 10px 30px 10px 15px;
  color: #fff;
  font: 300 16px/19px $roboto;
  resize: none;
  transition: background 0.2s;

  &::-webkit-placeholder-placeholder {
    color: $grey-font;
    text-transform: lowercase;
  }

  &::-moz-placeholder {
    color: $grey-font;
    text-transform: lowercase;
  }

  &:-moz-placeholder {
    color: $grey-font;
    text-transform: lowercase;
  }

  &:-ms-input-placeholder {
    color: $grey-font;
    text-transform: lowercase;
  }

  &:focus::-webkit-placeholder-placeholder {
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  &:focus::-moz-placeholder {
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  &:focus:-moz-placeholder {
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  &:focus:-ms-placeholder-placeholder {
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  &.active {
    border: 1px solid #ff9c43;
  }

  &.error {
    border-color: $red;
  }

  &__clear {
    display: none;
    position: absolute;
    right: 50px;
    top: 37px;
    width: 10px;
    height: 10px;
    background-image: url("../assets/images/icons/clear.svg");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer;
    z-index: 100;
  }

  // @include display-less(desktop) {
  //   width: 500px;
  // }
}

.basket-comment.active + .basket-comment__clear {
  display: block;
}

.basket-popup {
  box-sizing: border-box;
  width: 250px;
  color: #ffffff;
  display: block;
  position: fixed;
  background: #3f3f3f;
  font: 300 16px/18px $roboto;
  padding: 10px 15px;
  border-radius: 4px;
  text-align: center;
  z-index: 3;
  top: 80px;
  left: 30px;
  font-size: 16px;
  line-height: 30px;
  min-height: 50px;
  pointer-events: none;
}

.basket-popup {
  animation: css 0s 10s forwards;
  transition: (a);
}

@keyframes css {
  to {
    visibility: hidden;
  }
}
</style>
