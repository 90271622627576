<template>
  <div class="message-card" @click="openModal">
    <div class="message-card__icon">
      <img v-if="item.icon" :src="item.icon" alt="icon" />
      <img v-else :src="getIconUrl(item.type)" alt="icon" />
    </div>
    <div class="message-card__content">
      <div class="message-card__title-block">
        <div class="message-card__title">{{ item.title }}</div>
        <img class="message-card__close" src="../../assets/images/icons/close.svg" alt="close"
          @click.stop="closeNotification(item.id)" />
      </div>
      <div class="message-card__description" v-if="item.shortDescription">
        {{ item.shortDescription }}
      </div>
      <a target="_blank" v-if="computedLink" :href="computedLink.url" class="message-card__link" @click.stop>{{
        truncLongString(computedLink.text, 24) }} →</a>
    </div>
  </div>
</template>


<script>
import { truncateString } from "@/utils";

export default {
  name: "NotificationCard",
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  methods: {
    truncLongString(str, lenght) {
      return truncateString(str, lenght);
    },
    openModal() {
      this.$emit("open", this.item);
    },
    closeNotification(id) {
      this.$emit("close", id);
    },
    getIconUrl(icon) {
      const icons = {
        new_task: require("../../assets/images/icons/warning-icon.svg"),
        important_news: require("../../assets/images/icons/warning-icon.svg"),
        change_order_status: require("../../assets/images/icons/delivery-icon.svg"),
      };
      return icons[icon];
    },
  },
  computed: {
    computedLink() {
      if (this.item.link) {
        return { url: this.item.link, text: this.item.titleLink };
      }
      if (this.item.type === "change_order_status") {
        return { url: "/history", text: "К заказам" };
      }
      if (this.item.type === "new_task") {
        return { url: "/history", text: "Подробнее" };
      }
      return null;
    },
  },
};
</script>

<style lang="scss" scoped>
.message-card {
  display: flex;
  padding: 20px;
  border-radius: 4px;
  background-color: $grey-bg;
  margin-bottom: 10px;
  width: 373px;
  height: auto;
  overflow-wrap: break-word;
  transition: 0.8s;

  &:hover {
    opacity: 0.9;
    cursor: pointer;
  }

  @include display-less(tablet) {
    width: 360px;
    height: 137px;
    margin-bottom: 2px;
  }
}

.message-card__title-block {
  display: inline-flex;
  gap: 3px;
  align-items: flex-start;
  justify-content: space-between;

}

.message-card__icon {
  margin-right: 20px;
}

.message-card__icon img {
  width: 100px;
  height: 100px;
  border-radius: 4px;
}

.message-card__content {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.message-card__title {
  font: 500 16px/20px $roboto;
  color: $white;
  text-align: start;
  margin-bottom: 8px;
  overflow-wrap: break-word;
  hyphens: auto;
  width: 200px;

  @include display-less(tablet) {
    width: 180px;
  }

}

.message-card__description {
  font: 400 14px/23px $roboto;
  color: $gray-card;
  margin: 5px 0;
  text-align: left;
  overflow-wrap: break-word;
  hyphens: auto;
  display: -webkit-box;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  width: 210px;

  @include display-less(tablet) {
    width: 180px;
    hyphens: none;
    display: -webkit-box;
    -webkit-line-clamp: 2;
  }
}

.message-card__link {
  font: 400 14px/17px $roboto;
  color: $blue-card;
  text-decoration: none;
  margin-top: auto;
  text-align: left;
  width: fit-content;
}
</style>
