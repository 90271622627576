import Vue from 'vue'

export default {
  state: {
    history: {
      bonusHistory: [],
      expiringBonuses: []
    },
    offset: 1,
    limit: 10, 
  },

  mutations: { 
     SET_HISTORY(state, { history, reset }) {
      if (reset) {
        state.history = history;
      } else {
        state.history.bonusHistory = state.history.bonusHistory.concat(history.bonusHistory);
      }
    },
     SET_OFFSET(state, offset) {
      state.offset = offset;
    }
  },

  actions: {
    fetchHistory({ commit, state}, { reset = false } = {}) {
      const offset = reset ? 0 : state.offset;
      Vue.http.get( 
        `/api/history?limit=${state.limit}&offset=${offset}`
      )
      .then(response => { 
        const history = response.data; 
        commit('SET_HISTORY', { history, reset })
        commit('SET_OFFSET', offset + 1);
        if (history.bonusHistory.length < state.limit) {
          state.finished = true;
        }
      })
      .catch(error => {
        console.log( error );
      });
    }
  } 
}